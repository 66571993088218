
const heimdallIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M512 511.333q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 107.333q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'location_on',
        ],
        defaultCode: 57426,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 1,
        order: 1000,
        ligatures: '',
        prevSize: 32,
        code: 57426,
        name: 'location_on',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M320 0c-176 0-320 140.8-320 316.8s320 707.2 320 707.2 320-531.2 320-707.2-144-316.8-320-316.8zM320 444.8c-76.8 0-137.6-60.8-137.6-137.6s60.8-137.6 137.6-137.6 137.6 60.8 137.6 137.6-60.8 137.6-137.6 137.6z',
        ],
        width: 640,
        attrs: [],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'LOCATION',
        ],
        defaultCode: 59655,
        grid: 0,
      },
      attrs: [],
      properties: {
        id: 40,
        order: 9,
        ligatures: '',
        prevSize: 32,
        code: 59655,
        name: 'LOCATION',
      },
      setIdx: 0,
      setId: 4,
      iconIdx: 40,
    },
  ],
};

export default heimdallIcons;
