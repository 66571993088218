import './heimdallv2.scss';

import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import MediaQuery from 'react-responsive';
import { injectIntl, intlShape } from 'react-intl';
import RatingStars from '../RatingStars/RatingStars';
import Icon from '../../helpers/icon';
import tt from '../../helpers/translation';

// import paw from '../../images/maps/PA.jpg';
// import FL from '../../images/maps/FL.jpg';
// import AQ from '../../images/maps/CA.jpg';

import HeimdallIcons from './heimdall-icons';

const StyledImage = styled(Image)`
  grid-row: 1 / -1;
`;

class Heimdall extends React.Component {
  state = {};

  render() {
    const {
      data: {
        imageMobile,
        image,
        title,
        subtitle,
        location,
        starsHotel,
        starsColor,
        map,
        mapLink,
        subtitleType,
        name,
      },
      pageName,
      isHardCoded,
    } = this.props;

    let isSubtitle;
    if (subtitle) {
      if (subtitleType === 'div') {
        isSubtitle = (
          <div className="subtitle-container">
            <div className="text-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        );
      } else if (subtitleType === 'noborder') {
        isSubtitle = (
          <div className="subtitle-container-noborder">
            <div className="text-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        );
      } else {
        isSubtitle = (
          <div className="subtitle-container">
            <h2 className="text-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        );
      }
    }

    let isLocation;
    if (location) {
      isLocation = (
        <div className="text-location">
          <Icon icon="location_on" width="22" height="22" color="white" iconSet={HeimdallIcons} />
          <div className="text-location-text">{location}</div>
        </div>
      );
    }

    let isMap;
    if (map === 'pdf') {
      isMap = (
        <a className="map-container" target="_blank" rel="noreferrer" href={mapLink}>
          <div className="map">{tt('Tour virtual', this.props.intl.locale)}</div>
        </a>
      );
    } else if (map) {
      isMap = (
        <div className="map-container">
          {pageName && pageName === 'business' ? (
            <a
              className="map"
              rel="noreferrer"
              target="_blank"
              href={tt(
                'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/012/491/original/Planos_Centro_de_Convenciones.pdf',
                this.props.intl.locale
              )}
            >
              <Icon
                icon="LOCATION"
                width="20"
                height="20"
                class="icon-arrow"
                color="white"
                iconSet={HeimdallIcons}
              />
              {tt('VER PLANOS', this.props.intl.locale)}
            </a>
          ) : (
            <div className="map" onClick={this.props.handleMap}>
              <Icon
                icon="LOCATION"
                width="20"
                height="20"
                class="icon-arrow"
                color="white"
                iconSet={HeimdallIcons}
              />
              {tt('VER MAPA', this.props.intl.locale)}
            </div>
          )}
        </div>
      );
    }

    let isHotel;
    if (starsHotel) {
      isHotel = (
        <div className="stars-hotel-container">
          <div className="first-container-line">
            <div className="first-line" />
            <div className="second-line" />
          </div>
          <div className="second-container-line">
            <RatingStars count={starsHotel} color={starsColor} />
          </div>
          <div className="third-container-line">
            <div className="first-line" />
            <div className="second-line" />
          </div>
        </div>
      );
    }

    const isMobile = (
      <div className="mobile-heimdall">
        <MediaQuery minWidth={451} maxWidth={768}>
          <BackgroundImage
            fluid={image.url}
            alt={image.alt || 'Imagen cabecera'}
            backgroundColor="#040e18"
            className="img-heimdall"
            classId="1899"
          >
            <div className="img-grow">
              <div className="text-heimdall">
                <div className="title-header-pages">
                  {name && <h1 className="text-title">{name}</h1>}
                  {isHotel}
                  {isSubtitle}
                  {isLocation}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </MediaQuery>

        <MediaQuery minWidth={300} maxWidth={450}>
          <BackgroundImage
            fluid={(imageMobile && imageMobile.url) || image.url}
            backgroundColor="#040e18"
            className="img-heimdall"
            alt={image.alt || 'Imagen cabecera'}
            classId="1899"
          >
            <div className="img-grow">
              <div className="text-heimdall">
                <div className="title-header-pages">
                  {name && <h1 className="text-title">{name}</h1>}
                  {isHotel}
                  {isSubtitle}
                  {isLocation}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </MediaQuery>
      </div>
    );

    const isDesktop = (
      <BackgroundImage
        fluid={image.url}
        alt={image.alt || 'Imagen cabecera'}
        backgroundColor="#040e18"
        className="img-heimdall"
        classId="1899"
      >
        <div className="img-grow">
          <div className="text-heimdall">
            <div className="title-header-pages">
              {name && <h1 className="text-title">{name}</h1>}
              {isHotel}
              {isSubtitle}
              {isLocation}
              {isMap}
            </div>
          </div>
        </div>
      </BackgroundImage>
    );
    return (
      <div className="heimdall-header-container">
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobile} tablet={isDesktop} desktop={isDesktop} />
        </MediaServerRender>
      </div>
    );
  }
}
export default injectIntl(Heimdall);
